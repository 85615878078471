<template>
  <div id="privacy">
<h2> Terms and Conditions  </h2>
<h3> Last updated: March 15, 2021</h3>

Please read these Terms and Conditions ("Terms", "Terms and
Conditions") carefully before using the tenfactorgrowth.com website
(the "Service") operated by Ten Factor Growth ("us", "we", or "our
").

Your access to and use of the Service is conditioned upon your
acceptance of and compliance with these Terms. These Terms apply to
all visitors, users and others who wish to access or use the
Service. If you are accepting these Terms and using the Service on
behalf of a company, organization, government, or other legal entity,
you represent and warrant that you are authorized to do so. You may
use the Service only in compliance with these Terms and all applicable
local, state, national, and international laws, rules, and
regulations. 

By accessing or using the Service you agree to be bound by these
Terms. If you disagree with any part of the Terms then you do not have
permission to access the Service. 

<h6> Ownership </h6>
The Service and its original content, features and functionality are
and will remain the exclusive property of Ten Factor Growth and
its licensors. The Service is protected by copyright, trademark, and
other laws of both the United States and foreign countries. Our
trademarks and trade dress may not be used in connection with any
product or service without the prior written consent of Ten Factor
Growth. The information, materials, and other content of this web
site may not be copied, displayed, distributed, downloaded, licensed,
modified, published, reposted, reproduced, reused, sold, transmitted,
used to create a derivative work, or otherwise used for public or
commercial purposes without the express written consent of Ten Factor
Consulting. 

<h6> No Offer, Solicitation or Advice </h6>
The purpose of the Service is limited to providing you with general
information about us. Nothing on the Service is intended to constitute
or forms a part of (i) an offer, or solicitation of an offer, to
purchase or sell any security, other asset or service, (ii) investment
advice or an offer to provide such advice, or (iii) a basis for making
any investment decision.

<h6> International use </h6>
Ten Factor Growth makes no warranties that materials on this web
site are appropriate for use in countries other than the US. Because
the web site may be accessed internationally, you agree to comply with
all local laws, rules, and regulations including, without limitation,
all laws, rules and regulations in effect in the country in which you
reside and the country from which you access the web site. The
information on this web site is not intended for distribution to, or
use by, any person or entity in any jurisdiction or country where such
distribution or use would be contrary to law or regulation, or which
would subject Ten Factor Growth or its affiliates to any
registration requirement within such jurisdiction or country. 

<h6> Links to Other Web Sites </h6>
Our Service may contain links to third party web sites or services
that are not owned or controlled by Ten Factor Growth. 

Ten Factor Growth has no control over, and assumes no
responsibility for the content, privacy policies, or practices of any
third-party web sites or services. We do not warrant the offerings of
any of these entities/individuals or their websites. 

You acknowledge and agree that Ten Factor Growth shall not be
responsible or liable, directly or indirectly, for any damage or loss
caused or alleged to be caused by or in connection with use of or
reliance on any such content, goods or services available on or
through any such third party web sites or services. 

We strongly advise you to read the terms and conditions and privacy
policies of any third party web sites or services that you visit. 

<h6> Termination </h6>
We may terminate or suspend your access to the Service immediately,
without prior notice or liability, under our sole discretion, for any
reason whatsoever and without limitation, including but not limited to
a breach of the Terms. 

All provisions of the Terms which by their nature should survive
termination shall survive termination, including, without limitation,
ownership provisions, warranty disclaimers, indemnity and limitations
of liability. 

<h6> No Warranty </h6>
Your use of the Service is at your sole risk. The Service is provided
on an "AS IS" and "AS AVAILABLE" basis. The Service is provided
without warranties of any kind, whether express or implied, including,
but not limited to, implied warranties of merchantability, fitness for
a particular purpose, non-infringement or course of performance. 

Ten Factor Growth its subsidiaries, affiliates, and its licensors
do not warrant that a) the Service will function uninterrupted, secure
or available at any particular time or location; b) any errors or
defects will be corrected; c) the Service is free of viruses or other
harmful components; or d) the results of using the Service will meet
your requirements. 

<h6> Limitation of Liability </h6>
In no event shall Ten Factor Growth, nor its directors, employees,
partners, agents, suppliers, or affiliates, be liable for any
indirect, incidental, special, consequential or punitive damages,
including without limitation, loss of profits, data, use, goodwill, or
other intangible losses, resulting from (i) your access to or use of
or inability to access or use the Service; (ii) any conduct or content
of any third party on the Service; (iii) any content obtained from the
Service; and (iv) unauthorized access, use or alteration of your
transmissions or content, whether based on warranty, contract, tort
(including negligence) or any other legal theory, whether or not we
have been informed of the possibility of such damage, and even if a
remedy set forth herein is found to have failed of its essential
purpose. 

YOU UNDERSTAND AND AGREE THAT YOUR USE OF THIS WEB SITE IS PREDICATED
UPON YOUR WAIVER OF ANY RIGHT TO SUE TEN Factor Consulting OR ITS
AFFILIATES DIRECTLY OR TO PARTICIPATE IN A SUIT FOR ANY LOSSES OR
DAMAGES RESULTING FROM YOUR USE OF THIS WEB SITE. 

<h6> Indemnification </h6>
You agree to defend, indemnify and hold harmless Ten Factor Growth
and its licensee and licensors, and their employees, contractors,
agents, officers and directors, from and against any and all claims,
damages, obligations, losses, liabilities, costs or debt, and expenses
(including but not limited to attorney's fees), resulting from or
arising out of a) your use and access of the Service, or b) a breach
of these Terms. 

<h6> Exclusions </h6>
Some jurisdictions do not allow the exclusion of certain warranties or
the exclusion or limitation of liability for consequential or
incidental damages, so the limitations above may not apply to you. 

<h6> Governing Law </h6>
These Terms shall be governed and construed in accordance with the
laws of the Commonwealth of Massachusetts, without regard to its
conflict of law provisions. Any dispute relating to the above shall be
resolved solely in the state or federal courts located in
Massachusetts. 

Our failure to enforce any right or provision of these Terms will not
be considered a waiver of those rights. If any provision of these
Terms is held to be invalid or unenforceable by a court, the remaining
provisions of these Terms will remain in effect. These Terms
constitute the entire agreement between us regarding our Service, and
supersede and replace any prior agreements we might have had between
us regarding the Service. 

<h6> Privacy Policy; Use of Cookies </h6>
Your access to and use of the Service is conditioned upon your
acceptance of and compliance with our Privacy Policy, including our
policy on the use of cookies and similar tracking technologies to
track the activity on the Service. 

<h6> Changes </h6>
We reserve the right, at our sole discretion, to modify or replace
these Terms at any time. If a revision is material we will provide at
least 30 days notice prior to any new terms taking effect. What
constitutes a material change will be determined at our sole
discretion. 

By continuing to access or use our Service after any revisions become
effective, you agree to be bound by the revised terms. If you do not
agree to the new terms, you are no longer authorized to use the
Service. 

<h6> Contact Us </h6>
If you have any questions about these Terms, please contact us by
email: info@tenfactorgrowth.com 

  </div>
</template>

<script>
export default {
    name: 'Privacy',
    title: 'Privacy - Ten Factor Growth',
}
</script>
